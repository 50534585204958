import React from "react"
import Layout from "../components/layout"
import SearchComponent from "../components/SearchComponent"
import SEOHeader from "../components/seo-header"
import { Helmet } from "react-helmet"
export default () => (
  <div>
    <SEOHeader
      title="Find a coworking space near you | Gofloaters"
      description="Know an awesome spot that you think should be on the GoFloaters platform? Fill in the details and we’ll see what we can do."
      socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
      pinterest="true"
    ></SEOHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex"></meta>
    </Helmet>
    <Layout>
      <SearchComponent />
    </Layout>
  </div>
)
